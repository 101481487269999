<template>
  <div class="box box--md box--white">
    <table class="table table--list three-items">
      <tbody>
        <tr>
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Account</p>
              <h2 class="headline--md mt-4">{{ user.email }}</h2>
            </div>
          </td>
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Referal code</p>
              <h2 :class="['headline--md mt-4', { 'text-uppercase': user.referral_code }]">
                {{ user.referral_code || 'Not Available' }}
              </h2>
            </div>
          </td>
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Registered date</p>
              <h2 class="headline--md mt-4">{{ user.created_at | moment_lts }}</h2>
            </div>
          </td>
        </tr>

        <tr>
          <slot />
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Status</p>
              <h2
                class="headline--md mt-4 text-uppercase text-bold"
                :class="user.document_verification === 2 ? 'text-success' : 'text-error'"
              >
                {{ user.document_verification | format_verified_text }}
              </h2>
            </div>
          </td>
          <td v-if="user.blacklist">
            <p class="statistic__title text-warm">Black List</p>
            <h2 class="headline--md mt-4 text-uppercase text-bold text-error">
              {{ user.blacklist | format_blacklist_type }}
            </h2>
          </td>
          <td v-else />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'AccountInformation',

  props: {
    user: {
      type: Object,
      require: true
    }
  }
}
</script>
